<template>
  <div class="p-4 space-y-6 container">
    <TextInput
      ref="searchStrategySummaryInput"
      v-model="searchStrategySummary"
      :placeholder="`Summarize search strategy`"
      type="text"
      :multiline="true"
      :disabled="review.isPlanReadonly.value"
      @enter="(v) => updateSearchStrategySummary(v)"
    />
    <InclusionCriteria />
    <PlanScreening />
  </div>
</template>
<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import InclusionCriteria from './InclusionCriteria/InclusionCriteria.vue'
import PlanScreening from './PlanScreening/PlanScreening.vue'
import useSnackbar from '@app/composables/use-snackbar'
import useLoading from '@app/composables/use-loading'
import { ref } from 'vue'
import { SnackbarState } from '@app/types'
import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../use-review'

const review = injectStrict(ReviewKey)
const snackbar = useSnackbar()
const loading = useLoading()

const searchStrategySummary = ref(
  review.entity.value.plan?.searchStrategySummary,
)

async function updateSearchStrategySummary(newSearchStrategy: string) {
  try {
    loading.start()
    await review.updateSearchStrategySummary(newSearchStrategy)

    snackbar.show(
      SnackbarState.SUCCESS,
      'Search strategy summary updated successfully',
    )
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}
</script>
