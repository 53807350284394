<template>
  <Tabs
    v-model:step="activeStepNumber"
    v-model:sub-step="activeSubStepNumber"
    :steps="[
      {
        number: 1,
        title: 'AI Assistant',
      },
      {
        number: 2,
        title: 'User',
        children: [
          {
            number: 1,
            title: 'General',
          },
          {
            number: 2,
            title: 'Security',
          },
        ],
      },
      {
        number: 3,
        title: 'Extension',
      },
      ...(showBilling
        ? [
            {
              number: 4,
              title: 'Billing ',
            },
          ]
        : []),
      {
        number: 5,
        title: 'About',
      },
    ]"
    @update:step="updateStep"
    @update:sub-step="updateSubStep"
  >
    <template #step1>
      <AiAssistantSettings />
    </template>
    <template #[generalUserSubsctepName]>
      <GeneralUserSettings />
    </template>
    <template #[securityUserSubsctepName]>
      <SecurityUserSettings />
    </template>
    <template #step3>
      <ExtensionSettings />
    </template>
    <template #step4>
      <div class="container px-10 my-8 space-y-4">
        <a
          rel="noopener"
          :href="`${billingPortalUrl}?prefilled_email=${user?.email}`"
          target="_blank"
          class="px-4 py-2 bg-primary text-white rounded-md hover:brightness-110 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed disabled:hover:brightness-100"
        >
          Go to billing portal
        </a>
      </div>
    </template>
    <template #step5>
      <div class="container px-10 my-8 space-y-4 text-sm">
        {{ version }}
      </div>
    </template>
  </Tabs>
</template>
<script setup lang="ts">
import Tabs from '@app/components/Global/Tabs.vue'
import AiAssistantSettings from './AiAssistantSettings.vue'
import GeneralUserSettings from './UserSettings/General.vue'
import SecurityUserSettings from './UserSettings/Security.vue'
import ExtensionSettings from './ExtensionSettings.vue'
import { useRoute, useRouter } from 'vue-router'
import { computed, inject, ref } from 'vue'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Role } from '@core/domain/types/role.type'
const version = import.meta.env.VITE_EVIDENCE_VERSION
console.log('version', version)
const auth = injectStrict(AuthKey)
const user = auth.user
const isBillingDisabled = inject('isBillingDisabled')
const showBilling = computed(
  () => user.value?.role === Role.ADMIN && !isBillingDisabled,
)
const billingPortalUrl = inject('billingPortalUrl')

const route = useRoute()
const router = useRouter()
const activeStepNumber = ref(1)
const activeSubStepNumber = ref(1)
if (route.query.tab) {
  activeStepNumber.value = Number(route.query.tab)
}
if (route.query.subtab) {
  activeSubStepNumber.value = Number(route.query.subtab)
}
const generalUserSubsctepName = `step2-substep1`
const securityUserSubsctepName = `step2-substep2`

function updateStep(step: number) {
  router.push({ query: { tab: step, subtab: 1 } })
}

function updateSubStep(subStep: number) {
  router.push({ query: { tab: activeStepNumber.value, subtab: subStep } })
}
</script>
